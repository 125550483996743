<template>
  <Button
    icon="pi pi-clock"
    severity="secondary"
    @click="visible = true"
  />
  <Drawer v-model:visible="visible" header="Reminders" position="right">
    <template #header>
      <div class="flex gap-x-2 items-center">
        <span class="font-bold text-2xl">Reminders</span>
        <Button
          icon="pi pi-plus"
          rounded
          @click="dialog.open(DialogReminderAddChange, {
            props: {
              breakpoints:{
                '960px': '75vw',
                '640px': '90vw'
              },
              header: 'Add reminder',
              modal: true,
              style: {
                width: '30vw',
              }
            },
            onClose: () => refresh()
          })"
        />
      </div>
    </template>
    <DataView
      :alwaysShowPaginator="false"
      :loading="loading"
      :rows="pagination.per_page"
      :totalRecords="objectList?.count"
      :value="objectList?.results"
      class="border-none overflow-y-auto"
      dataKey="id"
      lazy
      paginator
      style="height: 350px"
      @page="(event) => { pagination = getPagination(event) }"
    >
      <template #list="{ items }">
        <ReminderCompact
          v-for="object in items"
          :key="object.id"
          :reminder="object"
          class="mb-5"
          @edit="dialog.open(DialogReminderAddChange, {
            props: {
              breakpoints:{
                '960px': '75vw',
                '640px': '90vw'
              },
              header: 'Edit reminder',
              modal: true,
              style: {
                width: '30vw'
              }
            },
            data: {
              reminder: object,
            },
            onClose: () => refresh()
          })"
        />
      </template>
    </DataView>
  </Drawer>
</template>

<script lang="ts" setup>
import DialogReminderAddChange from "~/components/dialogs/DialogReminderAddChange.vue";
import ReminderCompact from "~/components/cards/ReminderCompact.vue";

const { currentCompany} = useCompany()
const dialog = useDialog()
const { query } = useRoute()
const visible = ref<boolean>(false)

const pagination = ref<PaginationInput>({
  page: toNumber(query.page, 1),
  per_page: toNumber(query.per_page, 10)
})

const fetchQuery = computed(() => {
  return { ...pagination.value }
})

const { data: objectList, refresh, status } = useApi<Paginated<Reminder>>('/reminders/', {
  query: fetchQuery,
  watch: [currentCompany, pagination]
})

const loading = computed<boolean>(() => status === 'pending')
</script>
